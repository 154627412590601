<template lang="pug">
Navbar
template(v-if="!isMobileApp")
  .padding-top
    HeadingLayout(:title="title", :desc="desc")
template(v-else)
  HeadingLayout.mt-3(:title="title", :desc="desc")
.container
  .row.pb-5
    .col-12
      template(v-for="term in termService")
        .pb-3
          .fs-4.fw-bold {{ term.title }}
          template(v-for="subTerm in term.description")
            p {{ subTerm }}
Footer
</template>

<script>
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
import HeadingLayout from "../layout/HeadingLayout";
export default {
  name: "Term",
  components: { HeadingLayout, Footer, Navbar },
  data() {
    return {
      isMobileApp: true,
      title: "Kloser Terms of Service",
      desc: "Last revised: 3 January, 2022",
      termService: [
        {
          title: "Kloser Sdn Bhd — General Terms of Service",
          description: [
            "We are Kloser Sdn. Bhd. (Kloser,we,us and/or our) which own and operate www.Kloser.app which includes the Kloser Website and the Kloser Mobile App. Kloser provides an all-in-one personal branding, lead generation and personal CRM platform for our customers, helping them to generate digital leads to drive more sales with automated marketing campaigns via our platforms.",
            "Your use of the Site and the Mobile App is subject to these Terms of Service. By using the Site and/or the Mobile App, you accept and agree to be bound by these Terms of Service. Please note that these Terms and Conditions are subject to changes and updates from time to time, at the sole discretion of Kloser without prior notice to you. It is your responsibility to check these Terms of Service periodically for any changes and updates. You can determine when we last changed and updated these Terms of Service by referring to the “LAST UPDATED” statement below. Your continued use of the Site and/or the Mobile App constitutes your acceptance of and agreement to be bound by the current Terms of Service which include Changes that may be made from time to time. Should you disagree with the Terms of Service, please discontinue your access and usage of the Site.",
            "Your use of the Site and/or Mobile App (“the Kloser Platforms”) shall be at your own initiative and risk and in so doing, you are solely responsible for the compliance with all the applicable laws. By using the Kloser Platforms, you represent and warrant that you are permitted to do so under the applicable laws. You are solely responsible for all access to the Kloser Platforms using your Internet connection, even if the access is by another person.",
            "We reserve the right to restrict your access to the Kloser Platforms or part of it. Access to restricted areas of the Kloser Platforms may be subject to registration and other conditions. If we grant you permission to access a restricted area, we may at our sole and absolute discretion withdraw that permission at any time with or without notice (including when you breach any of these Terms of Service).",
          ],
        },
        {
          title: "Description of Service",
          description: [
            "Kloser is an all-in-one personal branding, lead generation and personal CRM platform primarily for but not limited to sales professionals and businesses around the world. Within our platforms, we provide our customers the tools and services to create personal landing pages, product pages, promotional advertisement setup, advertisement campaigns that run on multiple external sites and a sophisticated Customer Relationship Management (“CRM”) platform. With Kloser, customers can create a full-funnel marketing campaign to generate leads for their products and services, where they can then follow up with the leads to manage the sales and closing processes.",
          ],
        },
        {
          title: "App Subscription & Ad Package Purchases",
          description: [
            "The Kloser Platforms are free to download and free to use by anyone globally. However, to launch an advertisement campaign to drive leads, two (2) criterias are required:",
            "\u2022 The user needs to be a paid subscriber for the Kloser Mobile App.",
            "\u2022 The selected Ad Package needs to be fully paid off upfront, before the campaign can be published live.",
            "A purchase of an app subscription and ad package made on the Kloser Platforms indicate a contract between you as the customer and Kloser. As such, by your purchase of an app subscription and ad package made on the Kloser Platforms, you are bound to the Kloser Terms Of Service by default.",
          ],
        },
        {
          title: "Payment",
          description: [
            "The service is provided using a payment gateway service provider through a secure website. However, neither the payment gateway service provider nor Kloser gives any assurance that the information provided online by a user is secured or may not be read or intercepted by a third party. Kloser does not accept or assume any liability in the event of such unauthorized interception, hacking or other unauthorized access to information provided by a user of the service.",
          ],
        },
        {
          title: "Cancellation & Refunds",
          description: [
            "Kloser believes in making sound decisions and having a “never give up” mindset, hence we employ a 100% Zero Refund and Zero Cancellation policy. For app subscription purchases, your account will be valid until the paid period expires. For ad package purchases, your paid funds will be made available for a maximum of twelve (12 months) or until your ad campaigns fully exhaust the budget, whichever comes first. Neither the app subscription purchase nor the ad package purchase are available for refunds.",
          ],
        },
        {
          title: "Transparency on Tax & Kloser Fees",
          description: [
            "Kloser operates with full transparency on our fees. Please be informed that for both app subscription and ad package purchases, the amount charged to our customers are already inclusive of the required 6% Sales and Service Tax (a mandatory tax in the country which Kloser’s HQ operates in) and the 3% credit card transaction fees.",
            "For ad package purchases, the amount charged to our customers is inclusive of a 10% admin fee (of which, 3% goes to credit card transaction fees and 7% goes to Kloser’s admin fees). Once an ad package is purchased, the balance reflected on a user’s account will be 100% of the amount paid for and the 10% fee as mentioned above will be deducted accordingly in-line with the use of the budget as the user runs advertisement campaigns via the Kloser Platforms.",
          ],
        },
        {
          title: "Indemnity",
          description: [
            "Kloser provides an all-in-one personal branding, lead generation and personal CRM platform for our customers, helping them to generate digital leads to drive more sales with automated marketing campaigns via our platforms.",
            "Kloser shall not be liable for any potential damages or losses caused by using the Kloser Platforms. The Kloser Platform functions as a platform and you acknowledge that the accuracy and legality of the information, data and representations provided by you are solely your responsibility and yours alone. You further acknowledge that the effectiveness of the campaigns you run on the Kloser Platforms are highly dependent on how well you set up your assets on the Kloser Platforms and that Kloser does not guarantee any form of guaranteed performance deriving from your ad campaigns on the Kloser Platforms.",
          ],
        },
        {
          title: "Non-exclusive Arrangement",
          description: [
            "You acknowledge and understand that Kloser is free to render products and services on the Kloser Platforms to other customers and that Kloser does not make its product and services available exclusively to a particular customer.",
          ],
        },
        {
          title: "Ownership and Rights",
          description: [
            "All materials on the Kloser Platforms including its text, pictures, videos, look and feel, and content (with the exception of contributed content by Users) contain proprietary and or confidential information that is protected by applicable intellectual property and other laws. We and/or our licensors are the sole and absolute owner of these rights, title and interest in and to their applicable contributions to the Kloser Platforms. You may not print or otherwise make copies of any such content without our express prior permissions.",
          ],
        },
        {
          title: "Account Registration",
          description: [
            "To use and gain access to Kloser’s full range of features offered and made available, you will be prompted to complete the registration process via the Kloser Mobile App, in order to establish a Kloser User Account that grants access to you and you alone.",
          ],
        },
        {
          title: "Password and Security",
          description: [
            "You are responsible for maintaining the security and confidentiality of the pin/password that was provided to you upon your registration with Kloser via the Kloser Mobile App and are fully responsible for all activities that occur under your password or account.",
          ],
        },
        {
          title: "Privacy Policy",
          description: [
            "In the course of you using the Kloser Platforms, we may collect and use information about you in accordance with our Privacy Policy.",
          ],
        },
        {
          title: "Personal Use",
          description: [
            "Your permission to use the Kloser Platforms is personal to you and non-transferable, and you may not use the Site for other purposes other than as intended by Kloser. You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of the Kloser Platforms except as otherwise expressly provided in the Terms of Service or as specifically authorized in a separate written agreement. Your use of the Kloser Platforms and all related products and services is conditional on your compliance with the rules of conduct set forth in these Terms of Service and you agree that you shall not:",
            "\u2022 Use the Site for any fraudulent or unlawful purpose;",
            "\u2022 Use the Site to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others, including without limitation others’ privacy rights, rights of publicity or intellectual property rights;",
            "\u2022 Impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Kloser Platforms;",
            "\u2022 Interfere with or disrupt the operation of the Kloser Platforms or the services or networks used to make the Kloser Platforms available; or violate any requirements, procedures, policies or regulations of such networks;",
            "\u2022 Transmit or otherwise make available in connection with the Kloser Platforms any virus, worm, or other computer code that is harmful or invasive or may or is intended to damage the operation of, or to monitor the use of, any hardware, software or equipment;",
            "\u2022 Reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Kloser Platforms;",
            "\u2022 Modify, adapt, translate, reverse engineer, decompile or disassemble any portion of the Kloser Platforms;",
            "\u2022 Remove any copyright, trademark or other proprietary rights notice from the Kloser Platforms or materials originating from the Kloser Platforms;",
            "\u2022 Frame or mirror any part of the Kloser Platforms without our express prior written consent;",
            "\u2022 Create a database by systematically downloading and storing the Kloser Platforms’ content; and",
            "\u2022 Use any manual or automatic device in any way to gather content or reproduce or circumvent the navigational structure or presentation of the Kloser Platforms without our express prior written consent. Notwithstanding the foregoing, we grant the operators of public online search engines limited permission to use search retrieval applications to reproduce materials from the Kloser Platforms for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of such materials solely in connection with each operator’s public online search service. We reserve the right to revoke these exceptions either generally or in specific instances;",
            "\u2022 Create links that are unfair and illegal and or presented in a way that is misleading or could suggest any type of association, approval or endorsement by us which does not exist.",
          ],
        },
        {
          title: " Contributed Content",
          description: [
            "Some of the features on the Kloser Platforms may allow you to upload and or submit content (“Content”). By uploading and or submitting any Content, you represent and warrant that you have all right, title and interest to such posted Content, including but not limited to any consent, and authorization from any third party necessary for you to upload or submit the Content. By uploading or submitting Content, you further agree to give us a non-exclusive, royalty-free, perpetual, worldwide, transferable and sub-licensable licence to use, host, store, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display and distribute such content. The Content so uploaded and or submitted further shall not:",
            "\u2022 Infringe any intellectual property rights or otherwise constitute breach of any agreement with any person or entity;",
            "\u2022 Defame, be libellous, unlawfully threaten, harass or otherwise contain subject matters that are objectionable;",
            "\u2022 Be obscene, pornographic or indecent;",
            "\u2022 Be irrelevant for the purposes of the Services offered on the Kloser Platforms; and/or",
            "\u2022 Otherwise breach any law, statute or regulation.",
          ],
        },
        {
          title: "Linking",
          description: [
            "The Kloser Platforms may provide links to other websites and online resources. We are not responsible for and do not endorse such external sites or resources. Your use of third party websites and resources is at your own risk.",
          ],
        },
        {
          title: "Termination",
          description: [
            "Any Content uploaded, submitted and/or posted by Users are not necessarily pre-screened or reviewed by us for its appropriateness, reliability and or compliance with any laws, and we do not at any time assume such responsibility. We thus make no representation or warranty of any kind, express or implied as to the content uploaded or submitted by Users. Regardless, without assuming any obligation to do so, we may at our sole and absolute discretion, with or without notice and without liability delete any Content, suspend or delete any account that in our sole judgment violates any Term of Service. If you become aware of any misuse of our Services by other Users, please contact us by sending an e-mail to: support@kloser.app.",
          ],
        },
        {
          title: "Without Prejudice",
          description: [
            "The above is without prejudice to your right to terminate your account and end your use of the Kloser Platforms at any time and for any or no reason.",
          ],
        },
        {
          title: "User Representations, Warranties and Indemnities",
          description: [
            "You are solely responsible for the Contents uploaded or submitted on the Kloser Platforms and/or the manner with which you have used the Kloser Platforms. We do not endorse and have no control over the Content. For the avoidance of doubt, any use from your registered account or password shall be deemed to be used and thereby, publication or republication by you, wherever applicable, within the meaning and intent of section 114A of the Evidence Act 1950. If and when notified by the alleged copyright owner of the Content, we shall proceed to remove or disable any access to the Content subject to the section 43H of the Copyright Act 1987. You agree to indemnify and hold us, our affiliates, employees and agents harmless from any claim or demand made by any third party, whether actual or alleged that arise out in connection with your use of the Kloser Platforms, your violation of any terms and conditions of the Terms of Service, and/or arising out of in connection with the Contents uploaded or submitted by you on the Kloser Platforms.",
          ],
        },
        {
          title: "Disclaimer of Warranty and Limitation of Liabilities",
          description: [
            "We provide the Kloser Platforms on ‘as is’ and ‘as available’ bases. At no times should the estimated campaign performance contents provided in the Kloser Platforms be considered or construed as a guarantee of said performance estimation. We make no representations or warranties of any kind as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on the Kloser Platforms for any particular purpose, and to the fullest extent permissible by the applicable laws, we disclaim all said representations or warranties, express or implied. It shall be your own responsibility to ensure that any products, services or information available through the Kloser Platforms meet your specific requirements.",
          ],
        },
        {
          title: "Availability of The Kloser Platforms",
          description: [
            "We will use reasonable efforts to ensure that the Kloser Platforms are available at all times. However, we cannot guarantee that the Kloser Platforms or any individual function or feature of the Kloser Platforms will always be available. In particular, the Kloser Platforms may be unavailable during periods when we are implementing or carrying out essential maintenance work. The unavailability of the Kloser Platforms or certain content on the Kloser Platforms may be due to modification, and temporary or permanent discontinuance which we reserve the right to do so, with or without notice. You agree that we shall not be liable to you or to any third party for any unavailability, modification, or discontinuance of any content or features.",
          ],
        },
        {
          title: "Survival of Clauses",
          description: [
            "Clauses 6, and 11-18 will survive the suspension or termination of the Kloser Platforms for any reason whatsoever.",
          ],
        },
        {
          title: "Severability",
          description: [
            "If any term in these Terms of Service is or becomes invalid, illegal or unenforceable in any respect under the applicable laws, the validity, legality and enforceability of other terms shall not be affected or impaired in any way.",
          ],
        },
        {
          title: "Governing Law and Entire Agreement",
          description: [
            "These Terms of Service, together with our Privacy Policy, shall constitute the entire agreement between you and us in relation to your use of the Kloser Platforms and shall supersede all previous agreements between you and us in relation to your use of the Kloser Platforms. These Terms of Service shall be governed by and construed in accordance with the laws of Malaysia and parties irrevocably submit to the jurisdiction of the courts of Malaysia to hear and determine any dispute or differences out of and/or in connection to these Terms of Service.",
          ],
        },
      ],
    };
  },
  mounted() {
    this.isMobileApp = !!(
      this.$route.query && this.$route.query["is-mobile-app"]
    );
  },
};
</script>
